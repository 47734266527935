import React, { useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOptions,
  Button,
  ButtonGroup,
  Calendar,
  Divider,
  Grid,
  GridCol,
  Input,
  Tag,
  Select,
  SelectOption,
  Heading,
  Checkbox,
  SelectGroup
} from "@flixbus/honeycomb-react";
import {
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import {
  Icon,
  IconBus,
  IconBuses,
  IconCalendar,
  IconDownload,
  IconList
} from "@flixbus/honeycomb-icons-react";
import ApiCall from '../../helpers/ApiCall';
import Summary from '../Summary/Summary';
import Loader from '../../components/loader/Loader';
import DispatchContext from '../../contexts/DispatchContext';
import StateContext from '../../contexts/StateContext';
import Message from '../../components/message/Message';
import CustomAutoComplete from '../../components/autocomplete/CustomAutoComplete';
import Lines from '../../components/lines/Lines';
import EmptyLines from '../../components/lines/EmptyLines';
import DownloadExcel from '../../components/excel/DownloadExcel';

const Search = () => {
  const appDispatch = useContext(DispatchContext)
  const appState = useContext(StateContext);

  const allNeighbours = {
    key: "ALL",
    title: "ALL"
  }
  const [loading, setLoading] = useState(false);

  const [lineNumbers, setLineNumbers] = useState("");
  const [tripNumbers, setTripNumbers] = useState("");
  const [lines, setLines] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showStartDate, setShowStartDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const [endDateMax, setEndDateMax] = useState(new Date());


  const [hourFilter, setHourFilter] = useState(false);
  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(23);
  const [endMinute, setEndMinute] = useState(59);
  const [timeZone, setTimeZone] = useState("-1");

  const [stationsData, setStationsData] = useState([]);
  const [currentLine, setCurrentLine] = useState("");

  const [countries, setCountries] = useState([]);
  const [neighbours, setNeighbours] = useState([]);
  const [cities, setCities] = useState([]);

  const hours = Array.from({ length: 24 }, (_, index) => index);
  const minutes = Array.from({ length: 60 }, (_, index) => index);

  // a data set we'll be working with
  const [data, setData] = useState([]);
  // storing value as an array of title properties from the data set
  const [value, setValue] = useState("");
  // stores selection options, get's populated from data set
  const [selectedOptions, setSelectedOptions] = useState([]);


  const [country, setCountry] = useState();
  const [neighbour, setNeighbour] = useState(allNeighbours);
  const [city, setCity] = useState();

  const [national, setNational] = useState(false);

  const [searchResult, setSearchResult] = useState();
  const [fileName, setFileName] = useState("data.xlsx");
  const [searchId, setSearchId] = useState();
  const [excelId, setExcelId] = useState();


  const removeItemAtIndex = (indexToRemove) => {
    setLines([...lines.filter((_, index) => index !== indexToRemove)]);
  };

  const addList = () => {
    const uniques = [...lines, ...lineNumbers?.replace(/ /g, "").split(/[,;-]/)];
    const filtered = [...new Set(uniques)];
    setLines([...filtered.filter(item => item !== "")]);
    setLineNumbers("");
  }

  const addLine = () => {
    const combinedItems = [];
    if (tripNumbers?.length > 0) {
      for (const number of tripNumbers?.split(/[,-]/)) {
        combinedItems.push(`${value}-${number}`);
      }
      const uniques = [...lines, ...combinedItems];
      setLines([...new Set(uniques)]);
    }
    else {
      const uniques = [...lines, value];
      setLines([...new Set(uniques)]);
    }

    setTripNumbers("");
    setValue("");
  }

  const handleClearAll = () => {
    setLines([]);
    setCountry(null);
    setSearchResult();
  }

  useEffect(() => {
    const effectData = async () => {
      if (lines?.length === 0)
        setSearchResult();
    }
    effectData();
  }, [lines]);


  useEffect(() => {
    const effectData = async () => {
      if (countries?.length > 0) {
        let temp = [...countries];
        temp.unshift(allNeighbours);
        setNeighbours(temp);
      }

    }
    effectData();
  }, [countries]);


  const GetLineCode = async () => {
    try {
      appDispatch({ type: "startLoader" });
      const result = await ApiCall("GetLineCode", "Get", null);
      setStationsData(result?.data?.data);
    } catch (error) {
      console.log(error);
    }
    finally {
      console.log("finally");
      appDispatch({ type: "stopLoader" });
    }
  }

  const GetCountries = async () => {
    try {
      appDispatch({ type: "startLoader" });
      const result = await ApiCall("GetCountry", "Get", null);
      setCountries(transformJsonForCounties(result?.data?.data));
    } catch (error) {
      console.log(error);
    }
    finally {
      console.log("finally");
    }
    appDispatch({ type: "stopLoader" });
  }


  const handleGetPreparedList = async () => {
    try {
      appDispatch({ type: "startLoader" });
      const params = {
        startDate: startDate?.toLocaleDateString('tr-TR'),
        startTime: startHour?.toString().padStart(2, '0') + ":" + startMinute?.toString().padStart(2, '0'),
        endDate: endDate ? endDate.toLocaleDateString('tr-TR') : null,
        endTime: endHour?.toString().padStart(2, '0') + ":" + endMinute?.toString().padStart(2, '0'),
        timeZone: timeZone,
        country: country.key,
        neighbour: neighbour.key,
        city: city?.key
      }
      const result = await ApiCall("LineCodeByCountries", "Post", params);
      // setCountries(transformJsonForCounties(result?.data?.data));

      const uniques = [...lines, ...result?.data?.data];
      const filtered = [...new Set(uniques)];
      setLines([...filtered]);

    } catch (error) {
      console.log(error);
    }
    finally {
      console.log("finally");
    }
    appDispatch({ type: "stopLoader" });
  }

  useEffect(() => {
    const effectData = async () => {
      if (appState?.token) {
        await GetLineCode();
        await GetCountries();
      }
    }
    effectData();
  }, [appState?.token]);

  useEffect(() => {
    const effectData = async () => {
      setEndDate(startDate);
      setEndDateMax(await findMaxAddDate());
    }
    effectData();
  }, [startDate]);

  useEffect(() => {
    const effectData = async () => {
      country && await getCities();
    }
    effectData();
  }, [country]);




  const getCities = async () => {
    try {
      appDispatch({ type: "startLoader" });
      const result = await ApiCall("GetCity?country=" + country?.key, "Get", null);
      if (result?.data.success)
        setCities(transformJsonForCounties(result?.data?.data))
    } catch (error) {
      console.log(error);
    }
    finally {
      appDispatch({ type: "stopLoader" });
    }
  }


  const findMaxAddDate = async () => {
    const dateCount = 6;
    const newDate = new Date(startDate);
    newDate.setDate(newDate.getDate() + dateCount);
    return newDate;
  }





  const getSearch = async () => {
    const params =
    {
      startDate: startDate?.toLocaleDateString('tr-TR'),
      startTime: startHour?.toString().padStart(2, '0') + ":" + startMinute?.toString().padStart(2, '0'),
      endDate: endDate ? endDate.toLocaleDateString('tr-TR') : null,
      endTime: endHour?.toString().padStart(2, '0') + ":" + endMinute?.toString().padStart(2, '0'),
      lineTrip: lines,
      timeZone: timeZone,
      selectedCountry: country?.key,
      cityId: city?.key,
      national: national
    }

    try {
      appDispatch({ type: "startLoader" });
      const result = await ApiCall("GetSearchId", "Post", params);
      setSearchId(result?.data?.data);
      if (result?.data.success)
        appDispatch({ type: "stopLoader" });

      // const result = await ApiCall("GetSearchId", "Post", params);
      // setSearchResult(result?.data);
      setFileName(fileName);

      // const result = await ApiCall("GetId", "Post", params);
      // setId(result?.data?.data);

    } catch (error) {
      console.log(error);
    }
    finally {
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      let counter = 0; // Süre sayacı

      while (counter < 10 * 60) { // 10 dakika boyunca döngü devam edecek
        try {
          appDispatch({ type: "startLoader" });
          const result = await ApiCall("GetSearchResult?id=" + searchId, "Get", null);
          setSearchResult(result?.data);
          if (result?.data?.success) {
            appDispatch({ type: "stopLoader" });
            return; // Başarılı yanıt alındığında döngüden çık
          }
        } catch (error) {
          console.log(error);
        } finally {

        }
        await new Promise(resolve => setTimeout(resolve, 10 * 1000)); // Her döngü arası 10 saniye beklet
        counter += 10; // Zaman sayacını artır
      }
    };

    if (searchId) {
      fetchData(); // searchId değiştiğinde API isteği başlat
    }

    return () => {
      // Component unmount olduğunda işlemi durdur
    };
  }, [searchId]);


  const transformJson = (inputJson) => {
    const transformedData = inputJson.map(item => {
      return {
        key: item.code,
        title: item.code + ' - ' + item.title
      };
    });
    return transformedData;
  }

  const transformJsonForCounties = (inputJson) => {
    const transformedData = inputJson.map(item => {
      return {
        key: item.code,
        title: item.name
      };
    });
    return transformedData;
  }


  const filterData = (searchQuery, data) => (
    new Promise((resolve) => {
      setTimeout(() => {
        const res = data.filter(item => (
          (item.code + item.title).toLowerCase().includes(searchQuery.toLowerCase())
        ));
        resolve(res);
      }, 200);
    })
  );
  // --------------------------

  // narrows down suggested options list based on user input
  const filterOptionsList = (e) => {
    setData([]);
    if (e.target.value.length > 1)
      filterData(e.target.value, stationsData).then(
        (options) => {
          setData(options);
        },
      );
  };
  // returns options that have their values stored
  const setSelectedOptionsFromValue = (value) => {
    setSelectedOptions(
      stationsData.filter(option => value.includes(option.code))
    );
  };
  // updating selected options every time a value gets updated
  useEffect(() => {
    setSelectedOptionsFromValue(value);
  }, [value]
  );
  // pushes selected option value to the state
  const handleItemSelect = (selectedItem) => {
    if (!value.includes(selectedItem.code)) {
      setValue(selectedItem.code);
    }
    setData([]); // hiding the options list
  };


  const copyAll = (text) => {
    navigator.clipboard.writeText(lines)
      .then(() => {
        console.log('Text copied to clipboard:', lines);
      })
      .catch(err => {
        console.error('Unable to copy text to clipboard', err);
      });
  }


  const getExcelId = async () => {
    try {
      // const params =
      // {
      //   startDate: startDate?.toLocaleDateString('tr-TR'),
      //   startTime: startHour?.toString().padStart(2, '0') + ":" + startMinute?.toString().padStart(2, '0'),
      //   endDate: endDate ? endDate.toLocaleDateString('tr-TR') : null,
      //   endTime: endHour?.toString().padStart(2, '0') + ":" + endMinute?.toString().padStart(2, '0'),
      //   lineTrip: lines,
      //   timeZone: timeZone,
      //   selectedCountry: country?.key
      // }
      // const response = await ApiCall('GetId', 'POST', params);
      // setExcelId(response?.data?.data);
      searchId && setExcelId(searchId);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      let counter = 0; // Süre sayacı

      while (counter < 10 * 60) { // 10 dakika boyunca döngü devam edecek
        try {
          appDispatch({ type: "startLoader" });
          const result = await ApiCall('GetExcelResult?id=' + excelId, 'Get', null);

          // Create a blob from the base64 data
          const byteCharacters = atob(result?.data?.data.docContent);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


          // Create a download link and trigger the download
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = (fileName)  // Specify the desired file name
          document.body.appendChild(link);
          link.click();

          // Clean up resources
          window.URL.revokeObjectURL(url);

          if (result?.data?.success) {
            appDispatch({ type: "stopLoader" });
            return; // Başarılı yanıt alındığında döngüden çık
          }
        } catch (error) {
          console.log(error);
        } finally {

        }
        await new Promise(resolve => setTimeout(resolve, 10 * 1000)); // Her döngü arası 10 saniye beklet
        counter += 10; // Zaman sayacını artır
      }
    };

    if (excelId) {
      fetchData(); // searchId değiştiğinde API isteği başlat
    }

    return () => {
      // Component unmount olduğunda işlemi durdur
    };
  }, [excelId]);



  const downloadExcel = async () => {
    try {
      const result = await ApiCall('GetExcelWithId', 'Get', null);

      // Create a blob from the base64 data
      const byteCharacters = atob(result?.data?.data.docContent);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = (fileName)  // Specify the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const options = [
    { id: 'StationTime', value: '-1', name: 'timeZone', label: 'Station Time', defaultChecked: true },
    { id: 'MyTime', value: Intl.DateTimeFormat().resolvedOptions().timeZone, name: 'timeZone', label: 'My Time' },
  ];

  return (
    <>
      <Lines lines={lines} removeItemAtIndex={removeItemAtIndex} copyAll={copyAll} handleClearAll={handleClearAll} />

      <Grid align='bottom' extraClasses='flix-space-2-top'>
        <GridCol size={12} md={2}>
          <Input
            id="startDate"
            label={"Start Date"}
            placeholder="DD.MM.YYYY"
            iconLeft={<Icon aria-hidden="true" InlineIcon={IconCalendar} />}
            onFocus={() => { setShowStartDate(true) }}
            value={new Date(startDate).toLocaleDateString('tr-TR')}
            onChange={(e) => setStartDate(e.target.value)}
            aria-live="polite"
          />
          <Calendar
            id="startDateCalendar"
            data-testid="startDateCalendar"
            extraClasses="new-item-date-calendar"
            hidden={!showStartDate}
            startDate={new Date(2023, 0, 1)}
            endDate={new Date(2032, 11, 31)}
            defaultMonth={new Date()}
            appearance={'birthday'}
            selected={startDate}
            handleSelect={(date) => {
              setStartDate(date);
              setShowStartDate(false)
            }}
          />
        </GridCol>

        <GridCol size={12} md={2}>
          <Input
            id="endDate"
            label={"End Date"}
            placeholder="DD.MM.YYYY"
            iconLeft={<Icon aria-hidden="true" InlineIcon={IconCalendar} />}
            onFocus={() => { setShowEndDate(true) }}
            value={endDate ? endDate.toLocaleDateString('tr-TR') : ""}
            onChange={(e) => setEndDate(e.target.value)}
            aria-live="polite"
          />
          <Calendar
            id="calendar2"
            extraClasses="new-item-date-calendar"
            hidden={!showEndDate}
            startDate={startDate ? startDate : new Date(2023, 0, 1)}
            endDate={endDateMax}
            defaultMonth={new Date()}
            appearance={'birthday'}
            selected={endDate}
            handleSelect={(date) => {
              setEndDate(date);
              setShowEndDate(false)
            }}
          />
        </GridCol>
        <GridCol size={12} md={2}>
          <Checkbox label="Hour Filter" id='hourFilter' value={hourFilter} onClick={() => setHourFilter(!hourFilter)} />
        </GridCol>
        <GridCol size={12} md={3}>
          <SelectGroup
            label=""
            options={options}
            id="select-group-timezone"
            onChange={(e) => setTimeZone(e.target.value)}
          />
        </GridCol>
      </Grid>

      {
        hourFilter &&
        <Grid align="bottom">
          <GridCol size={12} md={1}>
            <Select id="startHour" name="startHour" label="Start Time" onChange={e => setStartHour(e.target.value)} value={startHour}>
              {hours.map(hour => (
                <SelectOption key={hour} value={hour}>
                  {hour.toString().padStart(2, '0')}
                </SelectOption>
              ))}
            </Select>
          </GridCol>
          <GridCol size={12} md={1}>
            <Select id="startMinute" name="startMinute" aria-label='Start Minute' onChange={e => setStartMinute(e.target.value)} value={startMinute}>
              {minutes.map(minute => (
                <SelectOption key={minute} value={minute}>
                  {minute.toString().padStart(2, '0')}
                </SelectOption>
              ))}
            </Select>
          </GridCol>
          <GridCol size={12} md={1}>
            <Select id="endHour" name="endHour" label="End Time" onChange={e => setEndHour(e.target.value)} value={endHour}>
              {hours.map(hour => (
                <SelectOption key={hour} value={hour}>
                  {hour.toString().padStart(2, '0')}
                </SelectOption>
              ))}
            </Select>
          </GridCol>
          <GridCol size={12} md={1}>
            <Select id="endMinute" name="endMinute" aria-label='End Minute' onChange={e => setEndMinute(e.target.value)} value={endMinute}>
              {minutes.map(minute => (
                <SelectOption key={minute} value={minute}>
                  {minute.toString().padStart(2, '0')}
                </SelectOption>
              ))}
            </Select>
          </GridCol>
        </Grid >
      }

      <Grid align='bottom'>
        <GridCol size={1} md={2}>
          <CustomAutoComplete
            data={countries}
            label={"Select Country"}
            value={country}
            setValue={setCountry}
          />
        </GridCol>
        <GridCol size={1} md={2}>
          <CustomAutoComplete
            data={neighbours}
            label={"Select Neighbour"}
            value={neighbour}
            setValue={setNeighbour}
          />
        </GridCol>
        <GridCol size={1} md={2}>
          <CustomAutoComplete
            data={cities}
            disabled={!country}
            label={"Select Station"}
            value={city}
            setValue={setCity}
          />
        </GridCol>
        <GridCol size={1} md={2}>
          <Checkbox label="National Pax Only" value={national} id='national' onChange={() => setNational(!national)} />
        </GridCol>
        <GridCol size={1} md={2}>
          <Button appearance='primary' disabled={!country} display="block" onClick={handleGetPreparedList}>
            Get Line List
          </Button>
        </GridCol>
      </Grid>

      {/* <Grid align='bottom'>
        <GridCol size={1} md={2}>
          <CustomAutoComplete
            data={cities}
            disabled={!country}
            label={"Select Station"}
            value={city}
            setValue={setCity}
          />
        </GridCol>
        <GridCol size={1} md={3}>
          <Checkbox label="National lines" value={national} id='national' onChange={() => setNational(!national)} />
        </GridCol>
        <GridCol size={1} md={2}>
          <Button appearance='primary' disabled={!country} display="block" onClick={handleGetPreparedList}>
            Get Line List
          </Button>
        </GridCol>
      </Grid> */}

      <Grid align="bottom">
        {/* <GridCol size={1} md={2}>
          <Input
            data-testid="lineNumbers"
            id="label"
            test-id="lineNumbers"
            label="Add Batch Lines"
            iconLeft={<Icon aria-hidden="true" InlineIcon={IconList} />}
            value={lineNumbers}
            onChange={(e) => setLineNumbers(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addList();
              }
            }}
          />
        </GridCol> */}

        <GridCol size={1} md={2}>
          {/* <Button display="block" disabled={!lineNumbers.replace(/ /g, "")} onClick={() => addList()}>
            Add Batch List
          </Button> */}
        </GridCol>
      </Grid>

      <Grid align="bottom">
        <GridCol size={12} md={2}>
          <Input
            data-testid="lineNumbers"
            id="label"
            test-id="lineNumbers"
            label="Add Batch Lines"
            iconLeft={<Icon aria-hidden="true" InlineIcon={IconList} />}
            value={lineNumbers}
            onChange={(e) => setLineNumbers(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addList();
              }
            }}
          />
        </GridCol>
        <GridCol size={12} md={2}>
          <Autocomplete
            placeholder="Try to search Line Number"
            label="Pick a Line"
            options={data}
            // filters the data based on value in the state filling out selected options list
            value={value}
            onDebounce={filterOptionsList}
            // onDelete={handleItemDelete}
            onSelect={handleItemSelect}
          >
            <AutocompleteInput
              id="autocomplete-non-multiselect"
              placeholder="Search with line number"
              label="Pick a Line"
              type="search"
              iconLeft={<Icon aria-hidden="true" InlineIcon={IconBus} />}
            // onFocus allows option list to be shown on focus
            // onFocus={() => {
            //   setData(stationsData);
            // }}
            />
            {/* displays a dropdown with options */}
            <AutocompleteOptions
              label="stationName"
              optionsToDisplay={10}
              optionHasSubtitle={false}
              style={{ width: '450px', height: '300px' }}
              renderOption={(option) => (
                <option key={option?.code + "" + option?.title}>{option?.code + " - " + option?.title}</option>
              )}
            />
          </Autocomplete>
        </GridCol>

        <GridCol size={12} md={2}>
          <Input
            id="tripNumber"
            label="Trip Number"
            iconLeft={<Icon aria-hidden="true" InlineIcon={IconBuses} />}
            value={tripNumbers}
            onChange={(e) => setTripNumbers(e.target.value)}
          />
        </GridCol>

        <GridCol size={12} md={2}>
          <Button display="block" disabled={!value} onClick={() => addLine()}>
            Add Line
          </Button>
        </GridCol>

        <GridCol size={12} md={2}>
          <Button appearance='primary' display="block" disabled={!lines?.length > 0} onClick={() => getSearch()}>
            Search
          </Button>
        </GridCol>
      </Grid>

      {
        searchResult?.success &&
        <Summary
          result={searchResult}
        />
      }

      {searchResult?.data?.emptyLineNumbers.length > 0 &&
        <EmptyLines lines={searchResult?.data?.emptyLineNumbers} removeItemAtIndex={removeItemAtIndex} />}

      <DownloadExcel fileName={fileName} searchResult={searchResult} setFileName={setFileName} getExcelId={getExcelId} />

    </>
  );
}


export default Search;