import { Button, Heading, Popup, PopupSection, Spinner, SelectGroup, Fieldset, Legend, Radio } from '@flixbus/honeycomb-react';
import { Icon, IconCheck, IconCrossed } from '@flixbus/honeycomb-icons-react';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ApiCall from '../../../helpers/ApiCall';
import DispatchContext from '../../../contexts/DispatchContext';
import StateContext from '../../../contexts/StateContext';



const StatusChangePopup = ({ popupActive, setPopupActive, id, data, status }) => {
    const targetRef = useRef(null);

    const appDispatch = useContext(DispatchContext)
    const appState = useContext(StateContext);

    function closePopup() {
        setPopupActive(false);
    }

    const changeStatus = async (e) => {
        console.log(e.target.value);

        try {
            appDispatch({ type: "startLoader" });

            const params = {
                authorityRequestId: id,
                status: e.target.value
            }

            if (e.target.value === 5)
                return null;

            const result = await ApiCall("authority/ChangeStatusByFlix", "Post", params);
            console.log(result?.data?.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            appDispatch({ type: "stopLoader" });
        }
    }

    const isDisabled = (item) => {
        if (!Array.isArray(data?.documents) || data.documents.length === 0) {
            return false;
        }

        const hasType2 = data.documents.some(doc => doc.type === 2);

        return !hasType2 && item?.code === "5";
    };

    return (
        <Popup
            id="status-popup"
            aria-labelledby="status-popup-title"
            active={popupActive}
            onOverlayClick={closePopup}
            targetRef={targetRef}
        >
            <PopupSection type="title">
                Change status
            </PopupSection>
            <PopupSection type="actions" style={{ display: 'block' }}>
                <Fieldset role="radiogroup" extraClasses='radioFlex'>
                    {status?.map((item, index) => (
                        <Radio
                            style={{ position: 'absolute' }}
                            key={item.code}
                            name="unique-radio"
                            id={`my-unique-radio-${item.code}`}
                            value={item.code}
                            label={item.title}
                            disabled={isDisabled(item)}
                            defaultChecked={item.title === data.requestDetail.status}
                            onChange={(e) => changeStatus(e)}
                        />
                    ))}
                </Fieldset>
                <Button extraClasses='flix-space-2-top' appearance="secondary" onClick={closePopup}>Done</Button>
            </PopupSection>
        </Popup>
    );
}

export default StatusChangePopup;